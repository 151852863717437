body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: rgb(234 234 234);
}

@font-face {
  font-family: "Toyota-Black";
  src: local("Toyota-Black"),
    url(./fonts/ToyotaType-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Toyota-Light";
  src: url(./fonts/ToyotaType-Light.eot);
  src: local("Toyota-Light"), url(./fonts/ToyotaType-Light.woff) format("woff"),
    url(./fonts/ToyotaType-Light.ttf) format("truetype");
}

.acceptClassActive {
  background-color: white;
}

.acceptClassInactive {
  display: none;
}

.buttonAccept:hover {
  border-radius: 0px !important;
  background-color: #000000 !important;
  color: #fff !important;
  text-transform: none !important;
  text-decoration: none !important;
}

.buttonAccept {
  border-radius: 0px !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  text-transform: none !important;
  text-decoration: none !important;
}

.disableButton {
  text-transform: none !important;
}

.hideButton {
  text-transform: none !important;
}

.tableHeaderRow {
  font-weight: bolder !important;
  color: #000000 !important;
  border-bottom: 1px solid black !important;
}

.tableRow {
  padding: 10px !important;
  font-weight: 400 !important;
  border-bottom: none !important;
}

.showDetails > span {
  text-decoration: none;
}

.showDetails {
  flex-grow: 1;
}

.PrivateTabIndicator-colorSecondary-25 {
  background-color: #000000 !important;
}

.MuiTab-textColorInherit.Mui-selected {
  font-weight: bolder !important;
  background-color: #f4f6f8;
}

.MuiTab-root {
  font-size: 1.1rem;
  font-weight: bolder;
  text-transform: none !important;
}
.rowHighlight {
  background-color: rgb(237, 247, 237);
}
.rowHighlightDeleted {
  background-color: rgb(247, 237, 237);
}
.MuiInput-underline::before {
  border-bottom: none !important;
}
.MuiInput-underline::after {
  border-bottom: none !important;
}
input:-internal-autofill-selected {
  background-color: none !important;
}
.MuiFormHelperText-root {
  color: red !important;
}
/* .MuiFormControl-root {
  width: 98%;
} */
.customer-search-table {
  padding:12px !important;
  border-bottom: none !important;
}

.MuiInputBase-input {
  width: 95% !important;
}

.jss26 {
  background-color: #000000 !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 90px !important;
  }
}

.MuiTabs-centered {
  justify-content: space-evenly !important;
}

.SearchHighlight {
  text-align: center;
  background-color: #d8d8d8;
  padding: 4px;
  margin: 1px;
}

.label-font span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-weight: bold;
}

.checked span.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: darkgrey;
}

.checkBox span.MuiSwitch-colorSecondary.Mui-checked {
  color:black;
}

.switch-root span.MuiSwitch-root {
  width: 61px;
  height: 39px;
  padding: 11px;
}